'use client';

import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

import { Image } from '@/components/image';

import './slider.css';

import * as Icons from '@/components/icons';
import { Link } from '@/components/link';

export const CulturalSiteSlider = ({ items }) => {
  const [sliderRef] = useKeenSlider({
    slides: { perView: 'auto', spacing: 20 },
  });

  return (
    <div className="cultural-site-slider">
      <div
        ref={sliderRef}
        className="cultural-site-slider__container keen-slider"
        style={{ '--nb-slide': `${items.length}` }}
      >
        {items.map((slide) => {
          return (
            <div
              key={slide._uid}
              className="cultural-site-slider__item keen-slider__slide number-slide1"
            >
              <Image
                className="cultural-site-slider__item__image"
                image={slide.image}
                sizes="300px"
                draggable="false"
              />
              <div className="cultural-site-slider__item__overlay" />
              <h3 className="cultural-site-slider__item__title">
                <Link link={slide.link}>
                  <span>{slide.title}</span> <Icons.ChevronRight />
                </Link>
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};
