'use client';

import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { Button } from '@/components/button';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { isImage } from '@/helpers/image';
import { hasText, render } from '@/helpers/storyblok/rich-text';

import './index.css';

export const Teams = ({ blok }) => {
  const [isOpen, setIsOpen] = useState(
    blok.items.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}),
  );

  const toggleOpen = (index) => {
    setIsOpen((currentIsOpen) => ({
      ...currentIsOpen,
      [index]: !currentIsOpen[index],
    }));
  };

  return (
    <div
      {...storyblokEditable(blok)}
      id="teams"
      className="teams"
      data-animate={blok.animateOnMount ? true : undefined}
    >
      <MaxWidth>
        <div className="teams__wrap">
          {hasText(blok.title) ? (
            <h2
              className="teams__title"
              dangerouslySetInnerHTML={{ __html: blok.title }}
            />
          ) : null}
          {blok.items ? (
            <ul className="teams__items">
              {blok.items.map((item, index) => (
                <li
                  key={index}
                  className="teams__item"
                  {...storyblokEditable(item)}
                >
                  {isImage(item.image) ? (
                    <div className="teams__item__image">
                      <Image image={item.image} sizes="100vw" />
                    </div>
                  ) : null}
                  {hasText(item.fonction) || hasText(item.name) ? (
                    <div className="teams__item__top">
                      {hasText(item.name) ? (
                        <div className="teams__item__name">
                          {render(item.name)}
                        </div>
                      ) : null}
                      {hasText(item.fonction) ? (
                        <div className="teams__item__fonction">
                          {render(item.fonction)}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: isOpen[index] ? 'auto' : 0 }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                    className={classnames('teams__item__content', {
                      'teams__item__content--current': isOpen[index],
                    })}
                  >
                    <div className="teams__item__content__inner">
                      {hasText(item.content) && (
                        <div className="teams__item__content__text">
                          {render(item.content)}
                        </div>
                      )}
                    </div>
                  </motion.div>

                  {hasText(item.content) ? (
                    <div className="teams__item__button">
                      {hasText(item.text) ? (
                        <div className="teams__item__text">
                          {render(item.text)}
                        </div>
                      ) : null}
                      <Button
                        onClick={() => toggleOpen(index)}
                        rightIcon={
                          isOpen[index] ? 'chevron-up' : 'chevron-down'
                        }
                        theme="gold"
                      >
                        {isOpen[index] ? 'Fermer' : 'Voir plus'}
                      </Button>
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};
