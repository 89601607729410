'use client';

import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

import { Button } from '@/components/button';
import { Image } from '@/components/image';

import { hasText, render } from '@/helpers/storyblok/rich-text';

import './index.css';

import { Link } from '@/components/link';

import { markdownToHtml } from '@/helpers/string';

const WheelControls = (slider) => {
  let touchTimeout;
  let position;
  let wheelActive;

  function dispatch(e, name) {
    position.x -= e.deltaX;
    position.y -= e.deltaY;
    slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: position.x,
          y: position.y,
        },
      }),
    );
  }

  function wheelStart(e) {
    position = {
      x: e.pageX,
      y: e.pageY,
    };
    dispatch(e, 'ksDragStart');
  }

  function wheel(e) {
    dispatch(e, 'ksDrag');
  }

  function wheelEnd(e) {
    dispatch(e, 'ksDragEnd');
  }

  function eventWheel(event) {
    if (event.deltaX !== 0) {
      event.preventDefault();

      if (!wheelActive) {
        wheelStart(event);
        wheelActive = true;
      }

      wheel(event);
      clearTimeout(touchTimeout);

      touchTimeout = setTimeout(() => {
        wheelActive = false;

        wheelEnd(event);
      }, 50);
    }
  }

  slider.on('created', () => {
    slider.container.addEventListener('wheel', eventWheel, {
      passive: false,
    });
  });
};

export const HighlightsSlider = ({ items }) => {
  const [sliderRef] = useKeenSlider(
    {
      slides: { perView: 'auto', spacing: 40 },
    },
    [WheelControls],
  );

  return (
    <div
      ref={sliderRef}
      className="highlights__slider keen-slider"
      style={{ '--nb-slide': `${items.length}` }}
    >
      {items.length > 0
        ? items.map((item) => {
            const link = item.cta?.[0]?.link;
            const TitleElInner = link ? Link : 'span';
            return (
              <div
                key={item._uid}
                {...storyblokEditable(item)}
                className={classnames(
                  'keen-slider__slide',
                  'highlights__item',
                  `highlights__item--theme-${item.theme}`,
                  {
                    'highlights__item--text':
                      item.component === 'highlightsTextItem',
                  },
                )}
              >
                {item.component === 'highlightsTextItem' ? (
                  <>
                    <div className="highlights__image">
                      <Image
                        image={item.image}
                        sizes="300px"
                        draggable="false"
                      />
                    </div>

                    <div className="highlights__content">
                      <h3 className="highlights__content_title">
                        <TitleElInner
                          link={link}
                          dangerouslySetInnerHTML={{
                            __html: markdownToHtml(item.title),
                          }}
                        />
                      </h3>
                      {item.subTitle ? (
                        <div className="highlights__content__subtitle">
                          {render(item.subTitle)}
                        </div>
                      ) : null}

                      {item.cta?.length > 0 ? (
                        <div className="highlights__content__cta">
                          {item.cta.map((link) => {
                            return (
                              <Button
                                key={link._uid}
                                {...storyblokEditable(link)}
                                as="p"
                                rightIcon={link.icon}
                                theme={item.theme}
                              >
                                {link.title}
                              </Button>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="highlights__image">
                      <Image
                        image={item.image}
                        sizes="300px"
                        draggable="false"
                      />
                    </div>

                    <div className="highlights__content">
                      <h3 className="highlights__content_title">
                        <TitleElInner
                          link={link}
                          dangerouslySetInnerHTML={{
                            __html: markdownToHtml(item.title),
                          }}
                        />
                      </h3>
                      {item.subTitle ? (
                        <div className="highlights__content__subtitle">
                          {render(item.subTitle)}
                        </div>
                      ) : null}

                      {hasText(item.content) ? (
                        <div className="highlights__content__text">
                          {render(item.content)}
                        </div>
                      ) : null}

                      {item.cta?.length > 0 ? (
                        <div className="highlights__content__cta">
                          {item.cta.map((link) => {
                            return (
                              <Button
                                key={link._uid}
                                {...storyblokEditable(link)}
                                as="p"
                                rightIcon={link.icon}
                                theme={item.theme}
                              >
                                {link.title}
                              </Button>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};
