import classnames from 'classnames';
import React from 'react';

import './index.css';

/**
 * @param {Object} props
 * @param {any} props.children
 * @param {string|null} [props.className=null]
 */
export const MaxWidth = ({ children, className = null, ...rest }) => {
  return (
    <div className={classnames('max-width', className)} {...rest}>
      {children}
    </div>
  );
};
