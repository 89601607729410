'use client';

import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Ellipse } from '@/components/icons';
import { Link } from '@/components/link';
import { MaxWidth } from '@/components/max-width';

import { isLink } from '@/helpers/storyblok/is-link';

import './index.css';

export const LinksList = ({ blok }) => {
  const [currentLink, setCurrentLink] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const linksList = document.querySelector('.links-list');
      const targetElements = blok.links.map((link) =>
        document.querySelector(link.link.cached_url),
      );

      if (linksList) {
        const linksListRect = linksList.getBoundingClientRect();

        let matchedLink = null;
        for (let i = 0; i < blok.links.length; i++) {
          const targetElement = targetElements[i];
          if (targetElement) {
            const targetRect = targetElement.getBoundingClientRect();
            if (
              linksListRect.top <= targetRect.bottom &&
              linksListRect.bottom >= targetRect.top
            ) {
              matchedLink = blok.links[i]._uid;
              break;
            }
          }
        }

        setCurrentLink(matchedLink);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [blok.links]);

  return (
    <div
      {...storyblokEditable(blok)}
      className="links-list"
      data-animate={blok.animateOnMount ? true : undefined}
    >
      <MaxWidth>
        {blok.links && blok.links.length > 0 ? (
          <div className="links-list__wrapper">
            {blok.links.map((item) => {
              const MainEl = isLink(item.link) ? Link : 'div';
              const isCurrent = item._uid === currentLink;
              return (
                <div
                  key={item._uid}
                  {...storyblokEditable(item)}
                  className="links-list__item"
                >
                  <MainEl
                    link={item.link}
                    className={classnames('links-list__item__main', {
                      'links-list__item__main--current': isCurrent,
                    })}
                  >
                    {isCurrent ? <Ellipse /> : null}
                    <div className="links-list__item__main__title">
                      {item.title}
                    </div>
                  </MainEl>
                </div>
              );
            })}
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};
