import {
  MARK_LINK,
  MARK_TEXT_STYLE,
  render as renderRichText,
} from 'storyblok-rich-text-react-renderer';

import { Link } from '@/components/link';

export const render = (blok, options = {}) => {
  if (typeof blok === 'string') {
    return blok;
  }

  return renderRichText(blok, {
    ...options,
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        if (props.linktype === 'email') {
          return <Link href={`mailto:${props.href}`}>{children}</Link>;
        }

        return <Link href={props.href}>{children}</Link>;
      },
      [MARK_TEXT_STYLE]: (children) => {
        return <span>{children}</span>;
      },
      ...(options.markResolvers || {}),
    },
  });
};

const blokList = {};

/**
 * convert bloks to string
 */
const blokToString = (blok) => {
  const component = blok.component || '';
  const fn = blokList[component];
  return fn ? fn(blok) : '';
};

/**
 * Convert rich text element to string
 * @returns
 */
const elementToText = (content) => {
  return content && Array.isArray(content)
    ? content
        .map(({ text, content }) => {
          if (content && Array.isArray(content)) {
            return elementToText(content);
          }

          return text;
        })
        .join(' ')
        .replace(/  /g, ' ')
    : '';
};

/**
 * @param {import('@storyblok/js').ISbRichtext} richText
 * @returns {string}
 */
export const richTextToString = (richText) => {
  if (!richText) {
    return '';
  }

  const content = richText.content || [];

  return content.reduce((result, element) => {
    switch (element.type) {
      case 'blok':
        const attrs = element.attrs || {};
        const body = attrs.body || [];

        result += body.map(blokToString).join('');
        break;

      case 'bullet_list':
      case 'heading':
      case 'paragraph':
        result += elementToText(element.content) + ' ';
        break;

      default:
        break;
    }

    return result.trim();
  }, '');
};

/**
 * @param {import('@storyblok/js').ISbRichtext} richText
 * @returns {boolean}
 */
export const hasText = (richText) => {
  return (
    (typeof richText === 'string' && richText) ||
    richTextToString(richText).trim() !== ''
  );
};
