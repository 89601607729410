'use client';

import * as Sentry from '@sentry/react';
import { storyblokEditable } from '@storyblok/js';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@/components/button';
import { Image } from '@/components/image';

import { pushDataLayerEvent } from '@/helpers/client/analytics/data-layer';
import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './form.css';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const Form = ({ blok }) => {
  const form = blok?.form[0];

  const formRef = useRef(null);

  const [state, setState] = useState(STATE.IDLE);

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const startFillingRef = useRef(null);
  useEffect(() => {
    if (startFillingRef.current === null) {
      startFillingRef.current = false;

      pushDataLayerEvent({
        event: 'form_view',
        form_name: 'contact',
      });
    } else if (startFillingRef.current === false) {
      startFillingRef.current = true;

      pushDataLayerEvent({
        event: 'form_completion',
        form_name: 'contact',
      });
    }
  }, [lastName, firstName, email, phone, subject, message]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setState(STATE.LOADING);

    const data = {
      site: 'Amaclio',
      lastName,
      firstName,
      email,
      phone,
      subject,
      message,
    };

    Sentry.withScope(async (scope) => {
      scope.setExtras(data);

      try {
        const response = await fetch('/api/form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error('Form submit error');
        }

        setState(STATE.SUCCESS);

        pushDataLayerEvent({
          event: 'form_submit',
          form_name: 'contact',
        });

        Sentry.captureMessage('Form submitted successfully');
      } catch (error) {
        setState(STATE.ERROR);

        Sentry.captureException(error);
      }
    });
  };

  const getSplitClass = (fields) => {
    const fieldCount = Object.values(fields).reduce(
      (count, field) => (field ? count + 1 : count),
      0,
    );

    return classnames('contact-form__form__split', {
      'contact-form__form__split--flex': fieldCount === 1,
    });
  };

  const canSubmit =
    email !== '' && EmailValidator.validate(email) === true && message !== '';

  return (
    <>
      <div
        {...storyblokEditable(blok)}
        className={classnames('contact-form__form', {
          'contact-form__form--state-loading': state === STATE.LOADING,
          'contact-form__form--state-success': state === STATE.SUCCESS,
          'contact-form__form--state-error': state === STATE.ERROR,
        })}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          {form.lastName || form.firstName ? (
            <div
              className={getSplitClass({
                lastName: form.lastName,
                firstName: form.firstName,
              })}
            >
              {form.lastName ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="text"
                    name="lastName"
                    placeholder={form.lastName}
                    autoComplete="family-name"
                    value={lastName}
                    onInput={(event) => setLastName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
              {form.firstName ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="text"
                    name="firstName"
                    placeholder={form.firstName}
                    autoComplete="given-name"
                    value={firstName}
                    onInput={(event) => setFirstName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {form.email || form.phone ? (
            <div
              className={getSplitClass({
                email: form.email,
                phone: form.phone,
              })}
            >
              {form.email ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="text"
                    name="email"
                    placeholder={form.email}
                    autoComplete="email"
                    value={email}
                    onInput={(event) => setEmail(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
              {form.phone ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="tel"
                    name="phone"
                    placeholder={form.phone}
                    autoComplete="tel"
                    value={phone}
                    onInput={(event) => setPhone(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {form.subject ? (
            <div className="contact-form__form__input-container">
              <input
                className="contact-form__form__input"
                type="text"
                name="subject"
                placeholder={form.subject}
                autoComplete="subject"
                value={subject}
                onInput={(event) => setSubject(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </div>
          ) : null}
          {form.message ? (
            <div className="contact-form__form__input-container-textarea">
              <textarea
                className="contact-form__form__textarea"
                name="message"
                placeholder={form.message}
                autoComplete="no"
                value={message}
                onInput={(event) => setMessage(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </div>
          ) : null}

          <div className="contact-form__form__cta">
            <Button
              theme="primary-blue"
              disabled={
                [STATE.LOADING, STATE.SUCCESS].includes(state) || !canSubmit
              }
              loading={[STATE.LOADING].includes(state)}
              rightIcon="chevron-right"
            >
              {form.submit}
            </Button>
          </div>

          {state === STATE.ERROR ? (
            <div className="contact-form__form__error">
              {render(form.errorMessage)}
            </div>
          ) : null}
        </form>
      </div>
      {state === STATE.SUCCESS && form.success.length > 0
        ? form.success.map((success) => (
            <div key={success._uid} className="contact-form__form__success">
              <div className="contact-form__form__success__top">
                <Image image={success.icon} sizes="100vw" alt={success.icon} />
                <p
                  className="contact-form__form__success__sup-title"
                  dangerouslySetInnerHTML={{ __html: success.successTitle }}
                />
              </div>
              <p
                className="contact-form__form__success__title"
                dangerouslySetInnerHTML={{
                  __html: markdownToHtml(success.successContent),
                }}
              />
              <p
                className="contact-form__form__success__description"
                dangerouslySetInnerHTML={{ __html: success.messageText }}
              />
            </div>
          ))
        : null}
    </>
  );
};
