'use client';

import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import isMobile from 'is-mobile';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { hasText, render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './index.css';
import './play.css';

const isMobileState = isMobile();

export const HeroVideo = ({ blok }) => {
  const [mountEmbed, setMountEmbed] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(!isMobileState);

  const player = useRef(null);

  const handleReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);

  const videoUrl = blok.videoUrl?.url || '';

  const youtubeIdMatch = videoUrl && videoUrl.match(/[?&]v=([^&]+)/);

  const youtubeId = youtubeIdMatch ? youtubeIdMatch[1] : null;

  return (
    <div
      {...storyblokEditable(blok)}
      className={classnames('hero-video', {
        'hero-video--is-mobile': isMobileState,
        'hero-video--ready': isReady,
        'hero-video--playing': isPlaying,
        'hero-video--started': isStarted,
        'hero-video--layout-realisation': blok.layout === 'realisation',
        'hero-video--layout-default': blok.layout === 'default' || !blok.layout,
      })}
    >
      <div className="hero-video__player">
        <div className="hero-video__player__cover">
          <Image image={blok.backgroundImage} sizes="100%" />
        </div>

        <div className="hero-video__player__wrap">
          {mountEmbed && (
            <ReactPlayer
              ref={player}
              url={videoUrl}
              onStart={handleReady}
              playing={isPlaying}
              loop
              controls={isMobileState}
              muted={isMuted}
              width="100%"
              height={isMobileState ? '100%' : '202%'}
              config={{
                youtube: {
                  preload: true,
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com',
                  },
                  playerVars: {
                    playsinline: isMobileState ? 0 : 1,
                    modestbranding: 1,
                    showinfo: 0,
                    loop: 1,
                    playlist: youtubeId,
                  },
                },
              }}
            />
          )}
        </div>
      </div>

      <div className="hero-video__player__gradient" />

      <div className="hero-video__wrapper">
        <MaxWidth>
          <div className="hero-video__wrap">
            <div className="hero-video__content">
              {blok.title ? (
                <h1
                  className="hero-video__title"
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(blok.title),
                  }}
                />
              ) : null}

              {hasText(blok.subTitle) ? (
                <div className="hero-video__sub-title">
                  {render(blok.subTitle)}
                </div>
              ) : null}
            </div>
            <div className="hero-video__play">
              <a
                target="_blank"
                className="hero-video__play__link"
                href={videoUrl}
              ></a>
              <div className="hero-video__play__img-container">
                <img
                  className="hero-video__play__img"
                  src="/static/icons/play.png"
                  srcSet="/static/icons/play.svg"
                  alt=""
                  draggable={false}
                />
              </div>

              <span>{blok.playButtonLabel}</span>
            </div>
          </div>
        </MaxWidth>
      </div>
    </div>
  );
};
